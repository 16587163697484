import { BankPickerContainer } from 'components/src/components/Elements/PdfToCsvConverter/BankPickerContainer';
import { navigate } from 'gatsby';
import * as queryString from 'query-string';
import * as React from 'react';
import { AuthWrapper } from '../auth/AuthWrapper';
import { Header } from '../components/Header';
import { setCompanyId } from '../storage/sessionStorageHelpers';

const IndexPage = () => {
  React.useEffect(() => {
    const { companyId: companyIdUrlParam } = queryString.parse(location.search);

    if (companyIdUrlParam?.length) {
      setCompanyId(companyIdUrlParam);
    }
  }, []);

  return (
    <AuthWrapper>
      <Header>
        <span className="number">1.</span>
        Wybierz co będziesz konwertować
      </Header>
      <BankPickerContainer
        setBankName={(bankName) => {
          if (!bankName) {
            navigate(`/upuść-plik`);
          } else {
            navigate(`/upuść-plik?id=${bankName}`);
          }
        }}
      />
    </AuthWrapper>
  );
};

export default IndexPage;
